import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Checklisten für Einzug, Tierarzt, Versicherung, u.v.m."
const text =
  "Mit unseren Checklisten haben Sie den idealen Überblick über alle Aufgaben, die Sie als neuer Welpenbesitzer erledigen müssen. </br> Wählen Sie aus unterschiedlichen Themenbereichen über die komplette Welpenzeit, z.B. “Erstausstattung”, “Vorbereitung Einzug”, “Meine Termine und Fristen“, u.v.m., und sind so immer bestens vorbereitet."

const Checklisten = () => {
  return (
    <Layout>
      <SEO title={headline} />
      <Navigation />
      <FeatureHeader page={"checklisten"} headline={headline} text={text} />
      <FeatureLinks />
      <Footer />
    </Layout>
  )
}

export default Checklisten
